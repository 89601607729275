/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {  useParams } from 'react-router-dom';

import { clearAccountById, clearRelatedPerson, createAccount, createClient, getRelatedAccount, updateAccount } from '../../data/store/account/accountActions';
import { Form, Input, Row, Col, Layout, Button, Divider, Radio, Space, Select, Table } from 'antd';
import { nameRules, organizationNumberRules } from '../../helpers/validation';

import Container from '../../components/Container';

import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';

import RelatedPersons from './RelatedPersons/RelatedPersons';
import RelatedAvisors from './RelatedAdvisirs/RelatedAvisors';
import { getAdvisors } from '../../data/store/advisors/advisorAction';
import ModalInvateClient from './ModalInvateClient/ModalInvateClient';
import DeleteModal from './DeleteModal';
import { ReactComponent as Delete } from '../../assets/delete-icon.svg';
import './style.scss';
import ModalDeleteAccount from './ModalDeleteAccount';
import { isEqual } from 'lodash';

const { Header } = Layout;

const Account = ({accountId}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const [relatedPersons, setRelatedPersons] = useState([]);
  const [relatedAdvisors, setRelatedAdvisors] = useState([]);
  const [clientInvate, setClientInvate] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteAccountModal, setDeleteAccountModal] = useState(null);

  const type = Form.useWatch('type', form);
  const name = Form.useWatch('name', form);
  const code = Form.useWatch('company_number', form);

  const availableUsers = useSelector((state) => state.advisorReducer.availableUsers);
  const accountById = useSelector((state) => state.accountReducer?.accountById);
  const relatedUsers = useSelector((state) => state.accountReducer?.relatedUsers);

  useEffect(() => {
    if(accountId && accountId !== 'new') {
      dispatch(getRelatedAccount(accountId));
    } else if(accountId === 'new'){
      form.resetFields();
      setRelatedPersons([]);
      setRelatedAdvisors([]);
      dispatch(clearRelatedPerson(accountId));
      dispatch(clearAccountById());
    }
  }, [accountId]);
  

  useEffect(() => {
    accountId !== 'new' && dispatch(getRelatedAccount(accountId));
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
    return () => {
      form.resetFields();
      dispatch(clearRelatedPerson());
    };
  }, []);

  useEffect(() => {
    if(relatedUsers) {
      const advisors = relatedUsers.filter(i => i.role === 'ADVISER');
      const users = relatedUsers.filter(i => i.role !== 'ADVISER').map(i=> ({...i, isOwner: i.role === 'OWNER'}));
      setRelatedAdvisors(advisors);
      setRelatedPersons(users);
    }
  }, [relatedUsers]);
  

  useEffect(() => {
    if(accountId !== 'new') {
      form.setFieldsValue(accountById || {});
    }else {
      form.resetFields();
    }
  }, [accountId, accountById]);

  const validateForm = () => {
    const hasErrors = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
      const currentValue = form.getFieldsValue();
      const storedValue = type === 'private' 
      ? {type: accountById?.type, name: accountById?.name, description: accountById?.description}
      : {type: accountById?.type, company_name: accountById?.company_name, company_number: accountById?.company_number, description: accountById?.description};
      const isNeedSave = !isEqual(currentValue, storedValue);
      setIsFormValid(!hasErrors && !!type && type === 'corporate' ? !!code: !!name && isNeedSave);
  };

  useEffect(() => {
    validateForm();
  }, [type, name, code, accountById]);

  const handleCancel = () => {
    form.setFieldsValue(accountById || {});
  };

  const handleInviteClient = () => {
    setClientInvate(+accountId);
  };

  const handleCloseInvateModal = () => {
    setClientInvate(null);  
  };

  const handleDeleteAccountModal = () => {
    setDeleteAccountModal(accountId);  
  };

  const closeDeleteAccountModal = () => {
    setDeleteAccountModal(null);  
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (fields) => {
    if(accountId === 'new') {
      dispatch(createAccount({ data: fields, history }));
    } else {
      console.log('🚀 ~ onSubmit ~ fields:', fields);
      dispatch(updateAccount({accountId, data: fields}));
    }
  };

  return (
    <div className="crete-client-account">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t(accountId === 'new' ? 'ACCOUNT_CREATION' : 'ACCOUNT' )}
            </Button>
          </Col>
          
        </Row>
      </Header>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t(accountId === 'new' ? 'CREATE_ACCOUNT' : 'EDIT_ACCOUNT_DETAILS')}</h2>
        {accountId !== 'new' &&
          <Button
          type='text'
          icon={<Delete/>}
          iconPosition='end'
          className="portfolio-delete-btn"
          onClick={handleDeleteAccountModal}
        >
          {t('DELETE_ACCOUNT')}
        </Button>}
      </div>
      <div className="new-client-form-wrapper">
        <Container widthP={100}>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            className="new-client-form"
            onFieldsChange={() => validateForm()}
            onFinish={onSubmit}
          >
            <div className="new-client-wrapper">
              <Row>
                <Col>
                  <h2 className="setting-header-title">{t('ACCOUNT_TYPE')}</h2>
                  <Form.Item name="type" className="">
                    <Radio.Group className="checkbox-block" disabled={accountId !== 'new'}>
                      <Space direction="horizontal" align="center">
                        <CustomRadioButtom value="private">
                          {t('PRIVATE')}
                        </CustomRadioButtom>
                        <CustomRadioButtom value="corporate">
                          {t('CORPORATE')}
                        </CustomRadioButtom>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={48}>
                {type === 'private' ? (
                  <Col>
                    <Form.Item
                      label={t('NAME')}
                      name="name"
                      className="custom-required"
                      // rules={nameRules}
                      required={false}
                    >
                      <Input placeholder={t('NAME')} className="custom-input" />
                    </Form.Item>
                  </Col>
                ) : type === 'corporate' ? (
                  <Col>
                    <Form.Item
                      label={t('COMPANY_NAME')}
                      name="company_name"
                      className="custom-required"
                      // rules={nameRules}
                      required={false}
                    >
                      <Input
                        placeholder={t('COMPANY_NAME')}
                        className="custom-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('COMPANY_NUMBER')}
                      name="company_number"
                      className="custom-required"
                      rules={organizationNumberRules}
                      required={false}
                    >
                      <Input
                        placeholder={t('COMPANY_NUMBER')}
                        className="custom-input"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col>
                    <Form.Item
                      label={t(' ')}
                      name="name"
                      rules={nameRules}
                      required={false}
                    >
                      <Input
                        disabled={!type}
                        placeholder={t('NAME')}
                        className="custom-input"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col>
                  <Form.Item
                    name="description"
                    className="onboarding-textarea-purpose"
                    label={t('DESCRIPTION')}
                  >
                    <CustomTextarea rows={5} placeholder={t('DESCRIPTION')} />
                  </Form.Item>
                </Col>
                <Col className="tabs-btn-wrapper" span={6}>
                  <Button type="default" className="edit-btn" onClick={handleCancel}>
                    {t('CANCEL')}
                  </Button>
                  <Button
                    type="link"
                    className="optimise-btn"
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  >
                    {t(accountId === 'new' ? 'REGISTER': 'SAVE')}
                  </Button>
                </Col>
              </Row>
              <Divider />
              {accountId !== 'new' &&
                <>
                  <Row className={'header-row'} align={'middle'} justify={'space-between'}>
                    <Col>
                      <h2 className="setting-header-title">
                        {t('RELATED_PERSONS')}
                      </h2>
                    </Col>
                    <Col>
                      <Button
                        style={{ marginTop: '24px' }}
                        onClick={handleInviteClient}
                        className="secondary-btn"
                      >
                        {t('INVITE_CLIENT')}
                      </Button>
                    </Col>
                  </Row>
                  <RelatedPersons
                    accountId={accountId}
                    users={availableUsers}
                    relatedPersons={relatedPersons}
                    setRelatedPersons={setRelatedPersons}
                    setDeleteModal={setDeleteModal}
                  />
                  <Row className={'header-row'} align={'middle'} justify={'start'}>
                    <Col>
                      <h2 className="setting-header-title">
                        {t('RELATED_ADVISORS')}
                      </h2>
                    </Col>
                  </Row>
                  <RelatedAvisors
                    accountId={accountId}
                    advisors={availableUsers}
                    relatedAdvisors={relatedAdvisors}
                    setRelatedAdvisors={setRelatedAdvisors}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                  />
                </>
              }{' '}
            </div>
          </Form>
          <ModalInvateClient
            open={clientInvate}
            closeModal={handleCloseInvateModal}
          />
          <DeleteModal
            accountId={+accountId}
            modalDeleteModel={deleteModal}
            handleDeleteClose={() => setDeleteModal(null)}
          />
          <ModalDeleteAccount
            t={t}
            history={history}
            name={accountById?.name ?? accountById?.company_name}
            deleteAccountModal={deleteAccountModal}
            closeDeleteAccountModal={closeDeleteAccountModal}
          />
        </Container>
      </div>
    </div>
  );
};

export default Account;

Account.propTypes = {
  accountId: PropTypes.string,
};