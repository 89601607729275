/* eslint-disable require-yield */
import {
  CHANGE_EMAIL,
  CLEAR_QR_BANKID,
  COLLECT_BANKID,
  CONFIRM_AUTH,
  CONFIRM_EMAIL,
  FORGOT_PASSWORD,
  GET_ADVISOR_BY_ID,
  GET_QR_ATTACH_BANKID,
  GET_QR_BANKID,
  GET_QR_SIGN_BANKID,
  GET_TWOFACTOR_CHECK,
  GET_TWOFACTOR_SECRET,
  GET_USER_BY_ID,
  LOGIN_USER,
  RESEND_EMAIL,
  RESET_USER_PASSWORD,
  SET_ADVISOR,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_PENDING,
  SIGN_UP_USER_REQUEST,
  UPDATE_ADVISOR_EMAIL,
  UPDATE_ADVISOR_NAME,
  UPDATE_ADVISOR_PASSWORD,
  UPDATE_USER_PHONES,
  USER_PROFILE_UPDATE
} from './userActionTypes';
import { put } from '@redux-saga/core/effects';
import { ACCOUNT, ACCOUNTS, LOGIN, PROFILE, TWO_FACTOR } from '../../../constants/routes';
import StorageService from '../../../services/StorageService';
import UserService from '../../../services/UserService';
import SessionService from '../../../services/SessionService';

export function * login (action) {
  console.log('🚀 ~ *login ~ action:', action);
  const { data, history, remember } = action.payload;
  try {
    yield put({ type: LOGIN_USER.REQUEST });
    const response = yield UserService.login(data);
    if (response.status === 200) {
      remember
        ? StorageService.setItem('acc', response.data.token)
        : SessionService.setItem('acc', response.data.token);
      remember
        ? StorageService.setItem('rfr', response.data.expire)
        : SessionService.setItem('rfr', response.data.expire);
      yield put({ type: LOGIN_USER.SUCCESS, payload: response.data });
      //TODO change
      if (response.data.role === 'ADVISER_NOT_SECURE' || response.data.role === 'CLIENT_NOT_SECURE') {
        history.push(PROFILE);
      } else if (response.data.role === 'CLIENT') {
        history.push(ACCOUNT);
      } else {
        history.push(ACCOUNTS);
      }
    } else {
      yield put({ type: LOGIN_USER.ERROR, payload: response?.response?.data?.message });
    }
  } catch (e) {
    if (e.response?.data?.code === 401 && e.response?.data?.message === 'ERR_AUTH_NEED_2FA') {
      history.push(`${LOGIN}${TWO_FACTOR}?email=${data?.email}`, { password: data?.password });
    } else {
      yield put({ type: LOGIN_USER.ERROR, payload: e.response?.data?.message });
    }
  }
}

export function * forgotPassword (action) {
  try {
    yield put({ type: FORGOT_PASSWORD.REQUEST });
    const response = yield UserService.forgotPasswordRequest(action.payload);
    if (response.status === 200) {
      yield put({ type: FORGOT_PASSWORD.SUCCESS });
    } else {
      yield put({ type: FORGOT_PASSWORD.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD.ERROR, payload: e.response.data.message });
  }
}

export function * confirmEmail (action) {
  try {
    yield put({ type: CONFIRM_EMAIL.REQUEST });
    const response = yield UserService.confirmEmail(action.payload);
    if (response.status === 200) {
      yield put({ type: CONFIRM_EMAIL.SUCCESS });
    } else {
      yield put({ type: CONFIRM_EMAIL.ERROR, payload: response.response?.data?.message });
    }
  } catch (e) {
    yield put({ type: CONFIRM_EMAIL.ERROR, payload: e.response?.data?.message });
  }
}

export function * resetUserPassword (action) {
  const { password, resetPasswordCode, history } = action.payload;
  try {
    yield put({ type: RESET_USER_PASSWORD.REQUEST });
    const response = yield UserService.forgotPasswordReset({ password, resetPasswordCode });
    if (response.status === 200) {
      yield put({ type: RESET_USER_PASSWORD.SUCCESS });
      history.push(LOGIN);
    } else {
      yield put({ type: RESET_USER_PASSWORD.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: RESET_USER_PASSWORD.ERROR, payload: e.response.data.message });
    history.push(LOGIN);
  }
}

export function * signUp (action) {
  try {
    yield put({ type: SIGN_UP_USER_REQUEST });
    const response = yield UserService.registration(action.payload);
    if (response.status === 200) {
      yield put({ type: SIGN_UP_USER_PENDING });
      yield put({ type: SET_ADVISOR.SUCCESS, payload: { ...response.data, ...action.payload } });
    } else {
      yield put({ type: SIGN_UP_USER_ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SIGN_UP_USER_ERROR, payload: e.response.data.message });
  }
}

export function * confirmAuth ({ payload }) {
  try {
    yield put({ type: CONFIRM_AUTH.REQUEST });
    const response = yield UserService.verifyEmail({ code: payload });
    if (response) {
      yield put({ type: CONFIRM_AUTH.SUCCESS });
    } else {
      yield put({ type: CONFIRM_AUTH.ERROR });
    }
  } catch (e) {
    yield put({ type: CONFIRM_AUTH.ERROR });
  }
}

export function * userProfileUpdate (action) {
  try {
    yield put({ type: USER_PROFILE_UPDATE.REQUEST });
    const response = yield UserService.userProfileUpdate(action.payload);
    if (response.status === 200) {
      yield put({ type: USER_PROFILE_UPDATE.SUCCESS, payload: response.data });
    } else {
      yield put({ type: USER_PROFILE_UPDATE.ERROR, payload: response?.response?.data.message });
    }
  } catch (e) {
    yield put({ type: USER_PROFILE_UPDATE.ERROR, payload: e.response?.data?.message });
  }
}

export function * getAdvisorById () {
  try {
    yield put({ type: GET_ADVISOR_BY_ID.REQUEST });
    const result = yield UserService.checkToken();
    const { id } = result.data;
    const response = yield UserService.getAdvisorById(id);
    if (response.status === 200) {
      yield put({ type: GET_ADVISOR_BY_ID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_ADVISOR_BY_ID.ERROR, payload: response?.response?.data.message });
    }
  } catch (e) {
    yield put({ type: GET_ADVISOR_BY_ID.ERROR, payload: e?.response?.data.message });
  }
}

export function * getUserById (action) {
  try {
    yield put({ type: GET_USER_BY_ID.REQUEST });
    const response = yield UserService.getAdvisorById(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_USER_BY_ID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_USER_BY_ID.ERROR, payload: response?.response?.data.message });
    }
  } catch (e) {
    yield put({ type: GET_USER_BY_ID.ERROR, payload: e?.response?.data.message });
  }
}

export function * updateAdvisorEmail (action) {
  try {
    const { id, fields } = action.payload;
    yield put({ type: UPDATE_ADVISOR_EMAIL.REQUEST });
    const response = yield UserService.updateEmail({ id, email: fields });
    if (response.status === 200) {
      yield put({ type: UPDATE_ADVISOR_EMAIL.SUCCESS, payload: fields });
    } else {
      yield put({ type: UPDATE_ADVISOR_EMAIL.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_ADVISOR_EMAIL.ERROR, payload: e.response.data.message });
  }
}

export function * updateAdvisorName (action) {
  try {
    const { id, fields } = action.payload;
    const response = yield UserService.updateName({ id, name: fields });
    if (response.status === 200) {
      yield put({ type: UPDATE_ADVISOR_NAME.SUCCESS, payload: fields });
    } else {
      yield put({ type: UPDATE_ADVISOR_NAME.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_ADVISOR_NAME.ERROR, payload: e.response.data.message });
  }
}

export function * updateUserPhones (action) {
  try {
    const { id, phones } = action.payload;
    const response = yield UserService.updateUserPhones({ id, phones });
    if (response.status === 200) {
      yield put({ type: UPDATE_USER_PHONES.SUCCESS, payload: response.data });
    } else {
      yield put({ type: UPDATE_USER_PHONES.ERROR, payload: response.response?.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_USER_PHONES.ERROR, payload: e.response?.data.message });
  }
}

export function * updateAdvisorPassword (action) {
  try {
    const { id, fields } = action.payload;
    const response = yield UserService.updatePassword({ id, password: { password: fields.password } });
    if (response.status === 200) {
      yield put({ type: UPDATE_ADVISOR_PASSWORD.SUCCESS });
    } else {
      yield put({ type: UPDATE_ADVISOR_PASSWORD.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_ADVISOR_PASSWORD.ERROR, payload: e.response?.data.message });
  }
}

export function * changeEmail (action) {
  try {
    yield put({ type: CHANGE_EMAIL.REQUEST });
    const response = yield UserService.emailChange(action.payload);
    if (response.status === 200) {
      yield put({ type: CHANGE_EMAIL.SUCCESS });
    } else {
      yield put({ type: CHANGE_EMAIL.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: CHANGE_EMAIL.ERROR, payload: e.response.data.message });
  }
}

export function * resendEmail (action) {
  try {
    yield put({ type: RESEND_EMAIL.REQUEST });
    const response = yield UserService.emailResend(action.payload);
    if (response.status === 200) {
      yield put({ type: RESEND_EMAIL.SUCCESS });
      alert('Email resend');
    } else {
      yield put({ type: RESEND_EMAIL.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: RESEND_EMAIL.ERROR, payload: e.response.data.message });
  }
}
// SignIn
export function * getQRCodeBankID () {
  try {
    // yield put({ type: GET_QR_BANKID.REQUEST });
    const response = yield UserService.getQRCodeBankID();
    if (response.status === 200) {
      yield put({ type: GET_QR_BANKID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_QR_BANKID.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_QR_BANKID.ERROR, payload: e.response.data.message });
  }
}

export function * getQRCodeSignBankID (action) {
  try {
    const response = yield UserService.getQRCodeSignBankID(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_QR_SIGN_BANKID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_QR_SIGN_BANKID.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_QR_SIGN_BANKID.ERROR, payload: e.response.data.message });
  }
}

export function * getQRCodeAttachBankID (action) {
  try {
    const response = yield UserService.getQRCodeAttachBankID(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_QR_ATTACH_BANKID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_QR_ATTACH_BANKID.ERROR, payload: response.response?.data?.message });
    }
  } catch (e) {
    yield put({ type: GET_QR_ATTACH_BANKID.ERROR, payload: e.response?.data?.message });
  }
}

export function * collectBankID (action) {
  try {
    const response = yield UserService.collectBankID(action.payload);
    if (response.status === 200) {
      yield put({ type: COLLECT_BANKID.SUCCESS, payload: response.data });
    } else {
      yield put({ type: COLLECT_BANKID.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: COLLECT_BANKID.ERROR, payload: e.response.data.message });
  }
}

export function * clearQRCodeBankID () {
  yield put({ type: CLEAR_QR_BANKID.REQUEST });
}

export function * getTwoFactorSecret () {
  try {
    yield put({ type: GET_TWOFACTOR_SECRET.REQUEST });
    const response = yield UserService.getTwoFactorSecret();
    if (response.status === 200) {
      yield put({ type: GET_TWOFACTOR_SECRET.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_TWOFACTOR_SECRET.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_TWOFACTOR_SECRET.ERROR, payload: e.response.data?.message ?? e.response.data });
  }
}

export function * getTwoFactorSecretCheck (action) {
  try {
    const { code, handleCloseModal } = action.payload;
    yield put({ type: GET_TWOFACTOR_CHECK.REQUEST });
    const response = yield UserService.getTwoFactorSecretCheck({ code });
    if (response.status === 200) {
      yield put({ type: GET_TWOFACTOR_CHECK.SUCCESS, payload: response.data });
      handleCloseModal();
      yield put({ type: GET_ADVISOR_BY_ID.ACTION });
    } else {
      yield put({ type: GET_TWOFACTOR_CHECK.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_TWOFACTOR_CHECK.ERROR, payload: e.response.data?.message ?? e.response.data });
  }
}

export function * logOut () {
  StorageService.removeJWTToken('acc');
  StorageService.removeItem('rfr');
  SessionService.removeJWTToken('acc');
  SessionService.removeItem('rfr');
}
