import { actionCreator } from '../../../helpers/actionCreator';

export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const SIMULATE_CLICK_ON_LINK = 'SIMULATE_CLICK_ON_LINK';
export const CLEAR_CONFIRM_STEP = 'CLEAR_CONFIRM_STEP';

export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SIGN_UP_USER_REQUEST = 'SIGN_UP_USER_REQUEST';
export const SIGN_UP_USER_PENDING = 'SIGN_UP_USER_PENDING';
export const SIGN_UP_USER_SUCCESS = 'SIGN_UP_USER_SUCCESS';
export const SIGN_UP_USER_ERROR = 'SIGN_UP_USER_ERROR';

export const IS_AUTH = 'IS_AUTH';
export const LOG_OUT = 'LOG_OUT';

export const GO_TO_CHANGE_EMAIL = 'GO_TO_CHANGE_EMAIL';

export const LOGIN_USER = actionCreator('LOGIN_USER');

export const FORGOT_PASSWORD = actionCreator('FORGOT_PASSWORD');

export const RESET_USER_PASSWORD = actionCreator('RESET_USER_PASSWORD');

export const GET_USER = actionCreator('GET_USER');

export const CONFIRM_AUTH = actionCreator('CONFIRM_AUTH');

export const CONFIRM_EMAIL = actionCreator('CONFIRM_EMAIL');

export const CHANGE_EMAIL = actionCreator('CHANGE_EMAIL');

export const USER_PROFILE_UPDATE = actionCreator('USER_PROFILE_UPDATE');

export const SET_ADVISOR = actionCreator('SET_ADVISOR');

export const GET_ADVISOR_BY_ID = actionCreator('GET_ADVISOR_BY_ID');

export const UPDATE_ADVISOR_NAME = actionCreator('UPDATE_ADVISOR_NAME');

export const UPDATE_ADVISOR_EMAIL = actionCreator('UPDATE_ADVISOR_EMAIL');

export const UPDATE_USER_PHONES = actionCreator('UPDATE_USER_PHONES');

export const UPDATE_ADVISOR_PASSWORD = actionCreator('UPDATE_ADVISOR_PASSWORD');

export const RESEND_EMAIL = actionCreator('RESEND_EMAIL');

export const GET_QR_BANKID = actionCreator('GET_QR_BANKID');

export const GET_QR_SIGN_BANKID = actionCreator('GET_QR_SIGN_BANKID');

export const CLEAR_QR_BANKID = actionCreator('CLEAR_QR_BANKID');

export const GET_QR_ATTACH_BANKID = actionCreator('GET_QR_ATTACH_BANKID');

export const COLLECT_BANKID = actionCreator('COLLECT_BANKID');

export const GET_TWOFACTOR_SECRET = actionCreator('GET_TWOFACTOR_SECRET');

export const GET_TWOFACTOR_CHECK = actionCreator('GET_TWOFACTOR_CHECK');

export const GET_USER_BY_ID = actionCreator('GET_USER_BY_ID');

export const CLEAR_CREATOR_FINPLAN = actionCreator('CLEAR_CREATOR_FINPLAN');
