/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { Form, Input, Row, Col, Button, Modal, Divider } from 'antd';

import { ReactComponent as DeleteClient } from '../../assets/delete-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Plus } from '../../assets/pluse-black-icon.svg';

import {
  // getUsers,
  // getAccount,
  updateClientInfo,
  deleteAccount,
  getAccount,
} from '../../data/store/account/accountActions';
import { emailRules, nameRules } from '../../helpers/validation';

import Container from '../Container';
import MainFooter from '../MainFooter';

import ModalUnsaveData from '../ModalUnsaveData/ModalUnsaveData';
import CustomPhoneInput from '../CustomPhoneInput/CustomPhoneInput';
import './style.scss';

const ClientProfile = ({
  userId,
  setUserId,
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();

  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const currentClient = useSelector((state) => state.accountReducer.accountById);

  const initData = () => {
    const data = {
      first_name: currentClient?.firstName,
      last_name: currentClient?.lastName,
      email: currentClient?.email,
      phones: currentClient?.phones?.map(i => ({ country_code: i.country_code, phone: i.phone })),
    };
    form.setFieldsValue(data);
  };

  const phones = Form.useWatch('phones', form);

  useEffect(() => {
    console.log('🚀 ~ phones:', phones);
  }, [phones]);

  useEffect(() => {
    if (currentClient) {
      initData();
    }
  }, [currentClient]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const { firstName, lastName } = currentClient || {};

  const handleReset = () => {
    form.resetFields();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    dispatch(getAccount({ id: userId, portfolio: false }));
  };

  // const handleAddMobilePhone = () => {
  //   if (phoneList.length < 3) {
  //     setPhoneList([...phoneList, { ID: uuidv4(), phone: '' }]);
  //     const allVAlue = form.getFieldsValue();
  //     handleValueChange('', allVAlue);
  //   }
  // };

  // const handleDelete = (id) => {
  //   const newData = phoneList.filter(i => i.ID !== id);
  //   setPhoneList(newData);
  //   const allVAlue = form.getFieldsValue();
  //   handleValueChange('', allVAlue);
  // };

  const handleValueChange = (_, allValues) => {
    const { email, first_name, last_name, phones } = allValues;
    const oldPhone = currentClient?.phones?.map(i => ({ country_code: i.country_code, phone: i.phone }));
    const isValid = !!email && !!first_name && !!last_name && phones.every(i => !!i.country_code && !!i.phone);
    console.log('🚀 ~ handleValueChange ~ isValid:', isValid);
    const isNeedSave = !(currentClient.email === email && currentClient.firstName === first_name &&
      currentClient.lastName === last_name && isEqual(oldPhone, phones));
    setNeedSave(isNeedSave && isValid);
  };

  const handleDeleteModal = () => {
    setModalDeleteVisible(true);
  };

  const handleCloseDelModal = () => {
    setModalDeleteVisible(false);
  };

  const submitDelete = () => {
    dispatch(deleteAccount(userId, history));
    setUserId(null);
    handleCloseDelModal();
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (fields) => {
    console.log('🚀 ~ onSubmit ~ fields:', fields);
    const clientFields = { ...fields };
    const client = { ...clientFields };
    dispatch(updateClientInfo({ id: userId, client, history, path: saveAlert.path }));
    setSaveAlert({ flag: false, path: '' });
    setNeedSave(false);
    closeModal();
  };

  // const validatePhoneNumber = (phone) => {
  //   const pattern = /\d+/;
  //   return pattern.test(phone);
  // };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <>
        <div className="top-btn-wrapper">
          <Button
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleReset}
          >
            {t('CANCEL')}
          </Button>
          <Button
            disabled={!needSave}
            onClick={handleSubmit}
            className="portfolio-details-btn btn-edit-save"
          >
            {t('SAVE')}
          </Button>
        </div>
        <div>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}
          >
            <Col>
              <h2 className="portfolio-header-title">{t('PROFILE')}</h2>
            </Col>
            <Col>
              <Button
                type="text"
                className="portfolio-delete-btn client-delete-btn"
                onClick={handleDeleteModal}
                icon={<DeleteClient />}
                iconPosition="end"
              >
                {t('DELETE_CLIENT')}
              </Button>
            </Col>
          </Row>
          <div style={{ padding: '0 24px' }}>
            <Container widthP={100} mb="24px" padding="0">
              <div className="profile-inner-wrapper">
                {currentClient && (
                  <Form
                    form={form}
                    name="client-profile"
                    layout="vertical"
                    onFinish={onSubmit}
                    onValuesChange={handleValueChange}
                  >
                    <div>
                      <Row className="profile-data-row">
                        <Col>
                          <Form.Item
                            className='custom-required'
                            label={t('FIRST_NAME')}
                            name="first_name"
                            rules={nameRules}
                            required={false}
                          >
                            <Input className="custom-input" />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            className='custom-required'
                            label={t('LAST_NAME')}
                            name="last_name"
                            rules={nameRules}
                            required={false}
                          >
                            <Input className="custom-input" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row className="profile-data-row phone-row">
                        <Col>
                          <Form.Item
                            className='custom-required'
                            label={t('EMAIL')}
                            name="email"
                            rules={emailRules}
                            required={false}
                          >
                            <Input className="custom-input" />
                          </Form.Item>
                        </Col>
                        <Form.List name='phones'>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Col key={key}>
                                  <Form.Item
                                    {...restField}
                                    label={`${t('MOBILE')}`}
                                    className="form-phone custom-required"
                                    name={[name, 'phone']}
                                    rules={[{
                                      required: true,
                                      message: `${t('ENTER_PHONE_NUMBER')}`,
                                    }]}
                                    required={false}
                                  >
                                    <CustomPhoneInput
                                      nameCode={[name, 'country_code']}
                                    />
                                  </Form.Item>
                                  {phones?.length > 1 &&
                                    <Delete
                                      className='profile-delete-btn'
                                      onClick={() => remove(key)} />
                                  }
                                </Col>
                              ))}
                              {phones?.length < 3 && (
                                <Col>
                                  <Button
                                    type="text"
                                    icon={<Plus />}
                                    iconPosition="end"
                                    className="profile-data-addbutton"
                                    onClick={() => add({ country_code: '', phone: '' })}
                                  >
                                    {t('ADD_MORE_PHONE_NUMBERS')}
                                  </Button>
                                </Col>
                              )}
                            </>
                          )}
                          </Form.List>
                      </Row>
                    </div>
                  </Form>
                )}
              </div>
            </Container>
          </div>
        </div>
      </>
      <Modal
        width={650}
        className="modal-content-paper"
        open={modalDeleteVisible}
        onCancel={handleCloseDelModal}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_TEXT_CLIENT')}</p>
            <p className="modal-advisor-name">{` ${firstName} ${lastName}`}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={submitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
      <MainFooter />
    </div>
  );
};

export default ClientProfile;

ClientProfile.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
