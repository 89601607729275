export const emailRules = [
  {
    required: true,
    type: 'email',
    message:
      'Enter a valid email address!'
  }
];

export const numberRules = [
  {
    required: true,
    message:
      'Enter phone number'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || getFieldValue('phone').match(/\d+/)) {
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The phone number must contain only numbers');
    }
  })
];
export const phoneRules = (name) => [
  {
    required: false,
    message:
      'Enter a phone number'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (value.slice(0, 3) !== '+46') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Phone must begin with +46');
      }
      if (isNaN(+value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Digits only');
      }
      if (value.length !== 12) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Phone must contain 12 digits');
      }
      if (!value || getFieldValue(name).match(/^(\+46\d{9}$)/)) {
        return Promise.resolve();
      }
    }
  })
];

export const passwordRules = [
  {
    required: true,
    message: 'Is required'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || getFieldValue('password').match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/)) {
        return Promise.resolve();
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Password is too short (minimum is 8 characters), needs at least 1 lowercase letter, and least 1 uppercase letter, and least 1 special symbol (~!@#$%^&*()_+) and 1 digit');
    }
  })
];

export const confirmPassRules = [
  {
    required: true,
    message: 'Please confirm your password!'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || (getFieldValue('password') === value && getFieldValue('password').match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/))) {
        return Promise.resolve();
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The two passwords that you entered do not match!');
    }
  })
];

export const confirmRules = [
  {
    required: true,
    message: 'Is required'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || (getFieldValue('password') === value && getFieldValue('password').match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/))) {
        return Promise.resolve();
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Password mismatch');
    }
  })
];

export const nameRules = [
  {
    required: true,
    message: 'Is required!'
  },
  () => ({
    validator (rule, value) {
      if (!value || (value.match(/\p{L}{2,50}\s*$/u))) {
        return Promise.resolve();
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Incorrect name');
    }
  })
];

export const isinRules = [
  {
    required: true,
    message: 'Is required!'
  },
  () => ({
    validator (rule, value) {
      if (value === 'N/A') {
        return Promise.resolve();
      }
      if (!value || value.length !== 12 || !/[A-Z]{2}[A-Z0-9]{10}/.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Wrong ISIN format (ex. DE0123456789)');
      }
      return Promise.resolve();
    }
  })
];

export const instrumentNameRules = [
  {
    required: true,
    message: 'Is required!'
  }
];

export const feeValidation = [
  {
    required: true,
    message: 'Is required!'
  },
  {
    transform: (value) => value && Number(value?.replace(',', '.')),
    type: 'number',
    message: 'Value should be number'
  },
  () => ({
    validator (rule, value) {
      if (!!value && value.toString().trim() === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should not be empty');
      }

      try {
        const num = parseInt(value);

        if (num < 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Should be 0 or greater');
        }

        return Promise.resolve();
      } catch (e) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be number');
      }
    }
  })
];

export const selectRules = [
  {
    required: true,
    message: 'Is required!'
  }
];

export const identificationNumberRules = [
  {
    required: true,
    message:
    'Enter identification number'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || getFieldValue('identification_number').match(/^(\d{4}((0\d)|(1[012]))(([012]\d)|3[01])\d{4}$)/)) {
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('YYYYMMDD-XXXX');
    }
  })
];
export const organizationNumberRules = [
  {
    required: true,
    message:
    'Enter organization number'
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || getFieldValue('company_number').match(/^(\d{2}[2-9]\d{3}\d{4}$)/)) {
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('XX[2-9]XXXXXXX');
    }
  })
];
export const zipRules = [
  {
    required: false,
    message: ''
  },
  ({ getFieldValue }) => ({
    validator (rule, value) {
      if (!value || getFieldValue('zip_code').match(/^(\d{5}$)/) || getFieldValue('zip_code').match(/^(\d{5}-\d{4}$)/)) {
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Enter valid zip code');
    }
  })
];

export const tickerRules = [
  () => ({
    validator (rule, value) {
      if (value && value.length > 30) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Wrong ticker length (max 30 characters)');
      }
      return Promise.resolve();
    }
  })
];
